import React, { useEffect, useState } from "react";
import "./first-form.styles.scss";
import {
  Alert,
  Autocomplete as MuiAutoComplete,
  Button,
  createTheme,
  AppBar,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import ListIcon from "@material-ui/icons/List";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import MapIcon from "@material-ui/icons/Map";
import _ from "lodash";
import Announcements from "../Announcements";
import Tabs from "@mui/material/Tabs";
import UploadComponent from "../../components/UploadComponent";
import Tab from "@mui/material/Tab";
import { Controller, useForm } from "react-hook-form";
import Logo from "../../assets/logo.webp";
import Autocomplete from "react-google-autocomplete";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MobileDatePicker, TimePicker } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Marker from "google-map-react";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import { CirclesWithBar, FallingLines } from "react-loader-spinner";
import { FormatTextdirectionLToR } from "@material-ui/icons";

var defaultTimeValue;
var defaultDateValue;
var addressSelected = "";
var minTime = moment("2013-02-08 08");
var maxTime = moment("2013-02-08 21");
const languages = require("language-list")();
const languagesList = [];
const multilineComponent = { style: { fontSize: 13 } };
var userFound = false;

const FirstForm = ({ isUser, setIsUser, display }) => {
  const [fileUpload, setFileUpload] = useState(false);
  const [events, setEvents] = useState(null);
  const defaultUtilityValue = (
    <MenuItem key="0" value={"N/A"}>
      N/A
    </MenuItem>
  );
  const params = useParams();
  const [itemID, setItemId] = React.useState(
    params.itemID ? params.itemID : ""
  );
  const [repLabel, setrepLabel] = React.useState(<p></p>);
  const [repRank, setRepRank] = React.useState("");
  const [geometryLocation, setGeometryLocation] = useState({
    lat: 35.0873,
    lng: -106.65,
  });
  const [mapCoordinates, setMapCoordinates] = useState("35.0873, -106.65");
  const [selectedDate, setValue] = useState(new Date());
  const [timeValue, setTimeValue] = React.useState(
    new Date("2020-01-01 12:00")
  );
  const [UtilitySelect, setUtilityValue] = React.useState([
    defaultUtilityValue,
  ]);
  const [extraUserInfo, setExtraUserInfo] = useState("");
  const [isInvalidUserError, setIsInvalidUserError] = React.useState(false);
  const [userErrorHelperText, setUserErrorHelperText] = React.useState("");
  const [roofFields, setroofFields] = React.useState("");
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();

  defaultTimeValue = moment(timeValue);
  defaultDateValue = moment(selectedDate);
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue: setFieldValue,
  } = useForm();

  const LocationPin = ({ text }) => (
    <div className="pin">
      <Icon icon="oi:map-marker" className="pin-icon" />
    </div>
  );

  const onSubmit = (data) => {
    if (data.Time === undefined) {
      data.Time = defaultTimeValue;
    }
    if (data.Date === undefined) {
      data.Date = defaultDateValue;
    }
    data.Date = data.Date.format("MM/DD/YYYY");
    data.Time = data.Time.format("HH:mm");
    data.AddressSelected = addressSelected;
    data.coordinates = mapCoordinates;
    data.itemID = itemID;
    if (userFound == false) {
      setIsUser(false);
      setUserErrorHelperText("Invalid Rep Id");
      setIsInvalidUserError(true);
      return;
    }
    setSubmitSuccess(false);
    setSubmitting(true);
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },

      body: JSON.stringify(data),
    };
    fetch(
      "https://hook.integromat.com/89kbgw2sf5f68bkupx4uypmrh8ixfcnn",
      requestOptions
    ).then((res) => {
      if (res.ok) {
        setSubmitting(false);
        setSubmitSuccess(true);
        navigate("/submitSuccess");
      }
    });
  };

  const watchJobSource = watch("jobSource");

  useEffect(() => {
    languages
      .getData()
      .forEach((countryEntry) => languagesList.push(countryEntry.language));
    if (itemID != "") itemIdCallback();
  }, []);

  const OnRoofRadioChange = (event) => {
    if (event.target.value == "no") setroofFields();
    else {
      setroofFields(
        <div>
          <div className="align-left">
            <FormLabel component="legend">Roof type &nbsp;</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value={"Shingle"}>Shingle</MenuItem>
                  <MenuItem value={"Tiled (12 Cent Adder)"}>
                    Tiled (12 Cent Adder)
                  </MenuItem>
                  <MenuItem value={"Metal (12 Cent Adder)"}>
                    Metal (12 Cent Adder)
                  </MenuItem>
                  <MenuItem value={"Flat (12 Cent Adder)"}>
                    Flat (12 Cent Adder)
                  </MenuItem>
                </Select>
              )}
              name="roofType"
              control={control}
              defaultValue={"Shingle"}
            />
          </div>
          <Controller
            render={({ field }) => (
              <UploadComponent
                label={"Roof Photo"}
                accept={"*"}
                folder={"roof-photo"}
                data={getValues().roofPhoto}
                setData={(f) => {
                  setFieldValue("roofPhoto", f || null);
                }}
              />
            )}
            name="roofPhoto"
            control={control}
            defaultValue={[]}
          />
          <br />
        </div>
      );
    }
  };
  const MondayApiCallback = (stateName) => {
    var query =
      "query { boards (ids:2139177078) { name items_page(limit:500){ items { group { title } name } } }}";
    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "API-Version": "2023-10",
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        UtilitySelect.length = 0;
        UtilitySelect.push(defaultUtilityValue);
        res.data.boards[0].items_page.items.forEach((item) => {
          if (item.group.title === stateName) {
            UtilitySelect.push(
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            );
            setUtilityValue(UtilitySelect);
          }
        });
      });
  };

  useEffect(() => {
    if (watchJobSource === "Event Marketing" && !events) {
      var query = `query {
        items_page_by_column_values (board_id: 5713162719,limit:500, columns:[{column_id: "status6", column_values: ["Active"]}]) {
          items{
          id
          name
          }
        }
      }
      `;
      fetch("https://api.monday.com/v2", {
        method: "post",
        headers: {
          "API-Version": "2023-10",
          "Content-Type": "application/json",
          Authorization:
            "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
        },
        body: JSON.stringify({
          query: query,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setEvents(res.data.items_page_by_column_values.items);
        });
    }
  }, [watchJobSource]);

  useEffect(() => {
    if (itemID.length == 10) {
      itemIdCallback();
    } else if (itemID.length > 0) {
      userFound = false;
      setIsUser(false);
      setrepLabel(<p className="error-message">Invalid sales rep id</p>);
    }
  }, [itemID]);

  const itemIdCallback = () => {
    let userEmail = null;
    userFound = false;
    setrepLabel(<p>Getting sales rep info...</p>);

    var query = `query {items (ids:${itemID}) { name column_values{ column {
      id
      title
    }
value } } }`;
    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "API-Version": "2023-10",
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const item = res.data.items?.[0];

        setrepLabel(
          <p>
            <b>Welcome {item.name}</b>
          </p>
        );
        userFound = true;
        setIsUser(itemID);
        userEmail = JSON.parse(
          item.column_values.find((c) => c.column.title === "Email").value
        )?.email;
        fetch(
          "https://us-central1-lifestyle-marketing.cloudfunctions.net/getSalesRep",
          {
            method: "post",
            body: JSON.stringify({
              afterDate: moment().startOf("month").format("YYYY-MM-DD"),
              beforeDate: moment().format("YYYY-MM-DD"),
              email: userEmail,
            }),
          }
        )
          .then((res) => res.json())
          .then((value) => {
            if (value.rank && value.totalAmount) {
              setRepRank(
                <p>
                  Rank: {value.rank} Total Sales: ${" "}
                  {Number(value.totalAmount || 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </p>
              );
            }
          });
        setUserErrorHelperText("");
        setIsInvalidUserError(false);

        if (!userFound) {
          setrepLabel(<p className="error-message">Invalid sales rep id</p>);
          return;
        }
      });
  };

  if (!display) {
    return <></>;
  }

  return (
    <div className="first-form">
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            paddingTop: "10%",
          }}
        >
          <div style={{ width: "50%" }}>
            <img
              style={{
                display: "block",
                width: "100%",
                height: "auto",
              }}
              src={Logo}
            />
            <h2>DIRECT</h2>
            <br />
            <br />
            <br />
            <h1>
              {repLabel}
              {repRank}
            </h1>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Paper
            className="form-background"
            elevation={9}
            style={{ background: "white" }}
          >
            <form className="inner-form" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Sales rep ID*"
                    error={isInvalidUserError}
                    helperText={userErrorHelperText}
                    {...field}
                    value={itemID}
                    onChange={(e) => {
                      setItemId(e.target.value);
                    }}
                  />
                )}
                name="itemID"
                control={control}
                defaultValue={itemID}
              />
              {isUser && (
                <>
                  <Autocomplete
                    className="google-autocomplete"
                    options={{
                      componentRestrictions: { country: "us" },
                      types: ["address"],
                    }}
                    onPlaceSelected={(place) => {
                      setExtraUserInfo("Loading info...");
                      fetch(
                        "https://hook.us1.make.com/1hjskpshx9qwm28ihy7868qlodjgjr8k",
                        {
                          method: "POST",
                          body: JSON.stringify({
                            address: place.formatted_address,
                          }),
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          console.log(res);
                          setFieldValue(
                            "firstName",
                            res?.Customer_Name?.split(" ")?.[0] || ""
                          );
                          setFieldValue(
                            "lastName",
                            res?.Customer_Name?.split(" ")
                              ?.slice(1)
                              ?.join(" ") || ""
                          );
                          setFieldValue("Utility", res?.utility);
                          setExtraUserInfo(
                            Object.keys(res)
                              .map(
                                (key) => `${_.startCase(key)}: ${res[key]}\n`
                              )
                              .join(" ")
                          );
                        })
                        .catch((e) =>
                          setExtraUserInfo("Error in fetching info")
                        );

                      addressSelected = place.formatted_address;

                      if (addressSelected !== undefined) {
                        setGeometryLocation({
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });
                        setMapCoordinates(
                          place.geometry.location.lat() +
                            ", " +
                            place.geometry.location.lng()
                        );
                        place.address_components.forEach((addressComponent) => {
                          if (
                            addressComponent.types[0] ===
                            "administrative_area_level_1"
                          ) {
                            MondayApiCallback(addressComponent.short_name);
                          }
                        });
                      } else {
                        addressSelected = "";
                      }
                    }}
                  />

                  <div className="google-map-container">
                    <GoogleMapReact
                      defaultZoom={13}
                      defaultCenter={{ lat: 35.0873, lng: -106.65 }}
                      center={geometryLocation}
                      options={{ mapTypeControl: true }}
                    >
                      <LocationPin
                        lat={geometryLocation.lat}
                        lng={geometryLocation.lng}
                      />
                      <Marker lat={35.0873} lng={-106.65} text="my marker" />
                    </GoogleMapReact>
                  </div>
                  <div style={{ whiteSpace: "pre-line" }}>{extraUserInfo}</div>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value = mapCoordinates, ref },
                    }) => (
                      <TextField label="Coordinates" value={mapCoordinates} />
                    )}
                    name="coordinates"
                    value={mapCoordinates}
                    control={control}
                    defaultValue=""
                  />

                  <Controller
                    render={({ field }) => (
                      <TextField
                        className="mui-button"
                        size="Large"
                        label="First name*"
                        error={errors?.firstName ? true : false}
                        helperText={errors?.firstName?.message}
                        {...field}
                      />
                    )}
                    name="firstName"
                    rules={{
                      required: "This field is required",
                      maxLength: {
                        value: 20,
                        message: "First name cannot exceed 20 characters",
                      },
                      pattern: {
                        value: /^[A-Za-z]+$/i,
                        message: "Alphabetical characters only",
                      },
                    }}
                    control={control}
                    defaultValue=""
                  />

                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Last name*"
                        error={errors?.lastName ? true : false}
                        helperText={errors?.lastName?.message}
                        {...field}
                      />
                    )}
                    name="lastName"
                    rules={{
                      required: "This field is required",
                      maxLength: {
                        value: 20,
                        message: "Last name cannot exceed 20 characters",
                      },
                      pattern: {
                        value: /^[A-Za-z]+$/i,
                        message: "Alphabetical characters only",
                      },
                    }}
                    control={control}
                    defaultValue=""
                  />

                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Email*"
                        error={errors?.email ? true : false}
                        helperText={errors?.email?.message}
                        {...field}
                      />
                    )}
                    name="email"
                    rules={{
                      required: "This field is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    }}
                    control={control}
                    defaultValue=""
                  />

                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Phone number*"
                        error={errors?.phone ? true : false}
                        helperText={errors?.phone?.message}
                        {...field}
                      />
                    )}
                    name="phone"
                    rules={{
                      required: "This field is required",
                      maxLength: {
                        value: 10,
                        message: "Number should be 10 digits",
                      },
                      minLength: {
                        value: 10,
                        message: "Number should be 10 digits",
                      },
                      pattern: {
                        value: /^[0-9]*$/gm,
                        message: "Please enter a valid phone number",
                      },
                    }}
                    control={control}
                    defaultValue=""
                  />

                  <Controller
                    render={({ field }) => (
                      <MuiAutoComplete
                        {...field}
                        options={languagesList}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Preferred Language*"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                    name="language"
                    control={control}
                    defaultValue={"English"}
                  />

                  <Controller
                    control={control}
                    name="Date"
                    render={({
                      field: { onChange, onBlur, value = selectedDate, ref },
                    }) => (
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <MobileDatePicker
                          disablePast
                          label="Date*"
                          value={value}
                          onChange={onChange}
                          selected={value}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <Controller
                    control={control}
                    name="Time"
                    render={({
                      field: { onChange, onBlur, value = timeValue, ref },
                    }) => (
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          renderInput={(params) => <TextField {...params} />}
                          label="Time*"
                          value={value}
                          onChange={onChange}
                          minTime={minTime}
                          maxTime={maxTime}
                          shouldDisableTime={(timeValue, clockType) => {
                            if (clockType === "minutes" && timeValue % 15) {
                              return true;
                            }
                            return false;
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />

                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Appointment notes"
                        multiline
                        inputProps={multilineComponent}
                        {...field}
                      />
                    )}
                    name="appointmentNotes"
                    control={control}
                    defaultValue=""
                  />

                  <div className="align-left">
                    <FormLabel component="legend">Utility &nbsp;</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select {...field}>{UtilitySelect}</Select>
                      )}
                      name="Utility"
                      control={control}
                      defaultValue={"N/A"}
                    />
                  </div>

                  <div className="align-left">
                    <FormLabel component="legend">
                      Send calendar reminder
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Controller
                      render={({ field }) => (
                        <RadioGroup
                          aria-label="Send calendar reminder"
                          {...field}
                        >
                          <div className="align-left">
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Y"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="N"
                            />
                          </div>
                        </RadioGroup>
                      )}
                      name="calenderReminder"
                      control={control}
                      defaultValue={"no"}
                    />
                  </div>

                  <div className="align-left">
                    <FormLabel component="legend">Bill In Hand</FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Controller
                      render={({ field }) => (
                        <RadioGroup aria-label="Bill In Hand" {...field}>
                          <div className="align-left">
                            <FormControlLabel
                              onChange={() => {
                                setFileUpload(true);
                              }}
                              value="yes"
                              control={<Radio />}
                              label="Y"
                            />
                            <FormControlLabel
                              onChange={() => {
                                setFileUpload(false);
                              }}
                              value="no"
                              control={<Radio />}
                              label="N"
                            />
                          </div>
                        </RadioGroup>
                      )}
                      name="billInHand"
                      control={control}
                      defaultValue={"no"}
                    />
                  </div>

                  {fileUpload && (
                    <Controller
                      render={({ field }) => (
                        <UploadComponent
                          label={"Upload bill"}
                          accept={"*"}
                          folder={"upload-bill"}
                          data={getValues().uploadBill}
                          setData={(f) => {
                            setFieldValue("uploadBill", f || null);
                          }}
                        />
                      )}
                      name="uploadBill"
                      control={control}
                      defaultValue={[]}
                    />
                  )}

                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Design preference"
                        multiline
                        inputProps={multilineComponent}
                        {...field}
                      />
                    )}
                    name="designPreference"
                    control={control}
                    defaultValue=""
                  />
                  <div className="align-left">
                    <FormLabel component="legend">Job source &nbsp;</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select {...field}>
                          <MenuItem value={"Direct To Home"}>
                            Direct To Home
                          </MenuItem>
                          <MenuItem value={"Event Marketing"}>
                            Event Marketing
                          </MenuItem>
                          <MenuItem value={"Referral"}>Referral</MenuItem>
                        </Select>
                      )}
                      name="jobSource"
                      control={control}
                      defaultValue={"Direct To Home"}
                    />
                  </div>
                  {watchJobSource === "Event Marketing" && events && (
                    <div className="align-left">
                      <FormLabel component="legend">Events &nbsp;</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <Select {...field}>
                            {events.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}-{id}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="event"
                        control={control}
                        defaultValue={""}
                      />
                    </div>
                  )}
                  <div className="align-left">
                    <FormLabel component="legend">
                      Is a new roof required
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Controller
                      render={({ field }) => (
                        <RadioGroup
                          aria-label="Is a new roof required"
                          {...field}
                        >
                          <div className="align-left">
                            <FormControlLabel
                              onChange={OnRoofRadioChange}
                              value="yes"
                              control={<Radio />}
                              label="Y"
                            />
                            <FormControlLabel
                              onChange={OnRoofRadioChange}
                              value="no"
                              control={<Radio />}
                              label="N"
                            />
                          </div>
                        </RadioGroup>
                      )}
                      name="roofRequired"
                      control={control}
                      defaultValue={"no"}
                    />
                  </div>
                  {roofFields}
                  {/* {submitting && <Alert severity="info">Submitting form</Alert>} */}

                  {submitSuccess && (
                    <Alert severity="success">
                      Thank you for submitting your lead
                    </Alert>
                  )}

                  {(Object.keys(errors).length > 0 || isInvalidUserError) && (
                    <Alert severity="error">
                      Please enter correct information
                    </Alert>
                  )}

                  {submitting ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CirclesWithBar
                        height="70"
                        width="70"
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        outerCircleColor=""
                        innerCircleColor=""
                        barColor=""
                        ariaLabel="circles-with-bar-loading"
                      />
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      disabled={submitting}
                      type="submit"
                      size="large"
                      style={{ background: "#1E3134", color: "white" }}
                      disableElevation
                    >
                      Submit Form
                    </Button>
                  )}
                </>
              )}
            </form>
          </Paper>
        </div>
      </div>
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: [
      '"Montserrat"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default function FirstFormWrapper() {
  const [value, setValue] = useState(0);
  const [isUser, setIsUser] = useState(false);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  const content = (
    <div style={{ height: "93vh", overflow: "auto" }}>
      <FirstForm isUser={isUser} setIsUser={setIsUser} display={value === 0} />
      {isUser && (
        <>
          {value === 1 && (
            <iframe
              src="https://sales-dashboard-6e4d5.web.app/"
              style={{ height: "93vh", width: "100vw", border: "none" }}
            />
          )}
          {value === 2 && (
            <iframe
              src="https://element-leaderboard.web.app/"
              style={{ height: "93vh", width: "100vw", border: "none" }}
            />
          )}
          {value === 3 && <Announcements />}
          {value === 4 && (
            <iframe
              src={`https://react-maps-shapes.web.app/?userId=${isUser}`}
              style={{
                height: "93vh",
                width: "100vw",
                border: "none",
                marginTop: 20,
              }}
            />
          )}
        </>
      )}
    </div>
  );

  const tabs = (
    <div style={{ height: "7vh", flexGrow: 1, width: "100%" }}>
      {isUser && (
        <AppBar position="static" color="default">
          <Tabs
            variant={isMobile ? "scrollable" : "fullWidth"}
            style={{
              background: "#1E3134",
              bottom: 0,
            }}
            value={value}
            textColor="secondary"
            indicatorColor="secondary"
            onChange={(e, newValue) => setValue(newValue)}
          >
            <Tab
              style={{ color: "white" }}
              label={isMobile ? undefined : "Form"}
              icon={isMobile ? <ListIcon /> : undefined}
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: "white" }}
              label={isMobile ? undefined : "Leaderboard"}
              icon={isMobile ? <AssignmentIndIcon /> : undefined}
              {...a11yProps(1)}
            />
            <Tab
              style={{ color: "white" }}
              label={isMobile ? undefined : "Element Leaderboard"}
              icon={isMobile ? <DeveloperBoardIcon /> : undefined}
              {...a11yProps(2)}
            />
            <Tab
              style={{ color: "white" }}
              label={isMobile ? undefined : "Announcements"}
              icon={isMobile ? <AnnouncementIcon /> : undefined}
              {...a11yProps(3)}
            />
            <Tab
              style={{ color: "white" }}
              label={isMobile ? undefined : "Lead Map"}
              icon={isMobile ? <MapIcon /> : undefined}
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
      )}
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: "100vh", width: "100vw" }}>
        {isMobile ? (
          <>
            {content}
            {tabs}
          </>
        ) : (
          <>
            {tabs}
            {content}
          </>
        )}
      </div>
    </ThemeProvider>
  );
}
